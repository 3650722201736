<template>
    <b-modal title="Create a Mailing List" id="createMailingList" hide-footer>
        <b-overlay :show="busySaving">
            <b-form>
                <b-form-group id="example-input-group-1" label="Name" label-for="example-input-1">
                    <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.name.$model"
                        :state="validateState('name')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field and must be at least 2 characters.</b-form-invalid-feedback
                    >
                </b-form-group>

                <b-form-group id="example-input-group-1" label="Description" label-for="example-input-1">
                    <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.description.$model"
                        :state="validateState('description')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field and must be at least 2 characters.</b-form-invalid-feedback
                    >
                </b-form-group>
                <b-form-group id="example-input-group-1" label="Email Template:" label-for="example-input-1">
                    <b-form-select
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.email_template_id.$model"
                        :state="validateState('email_template_id')"
                        :options="template_options"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-select>

                    <b-form-invalid-feedback id="input-1-live-feedback">Required</b-form-invalid-feedback>
                </b-form-group>
                <div class="modal-footer">
                    <b-button @click="onSubmit" variant="primary">Create</b-button>
                    <button type="button" class="btn btn-secondary" @click="closeModal()">Close</button>
                </div>
            </b-form>
        </b-overlay>
    </b-modal>
</template>

<script>
import { crmComputed, crmMethods } from "@/state/helpers";
//eslint-disable-next-line
import { validationMixin } from "vuelidate";
//eslint-disable-next-line
import { required, minLength } from "vuelidate/lib/validators";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    data: () => ({
        busySaving: false,
        formSubmit: false,
        form: {
            name: "",
            description: "",
            email_template_id: null,
        },
    }),
    validations: {
        form: {
            name: {
                required,
                minLength: minLength(2),
            },
            description: {
                required,
                minLength: minLength(3),
            },
            email_template_id: {
                required,
            },
        },
    },
    computed: {
        ...crmComputed,
        template_options() {
            let template_options = [{ value: null, text: "Please select an option", disabled: true }];

            this.email_templates.map((template) => {
                template_options.push({ value: template.id, text: template.name });
            });

            return template_options;
        },
    },
    methods: {
        ...crmMethods,
        validateState(name) {
            if (this.formSubmit) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            }
        },
        closeModal() {
            this.$bvModal.hide("createMailingList");
        },
        onSubmit() {
            //
            this.formSubmit = true;

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.busySaving = true;
            //eslint-disable-next-line
            this.saveMailingList(this.form).then((response) => {
                this.busySaving = false;

                Swal.fire(`Mailing list created`, `${this.form.name} `, "success");
                this.setAllMailingLists();
                this.closeModal();

                // this.setAllProductGroups();
                //
                //eslint-disable-next-line
                // console.log(response);
                // this.$router.push(`/product-groups/${response.group_id}`);
            });
        },
    },
    mounted() {
        //eslint-disable-next-line
        console.log("modal up");

        //set email templates
        this.setEmailTemplates();
    },
};
</script>
<style></style>
