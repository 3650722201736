<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="mb-2 d-flex justify-content-end">
                            <button type="button" class="btn btn-success mr-2" @click="createMailingList">
                                <i class="mdi mdi-plus mr-1"></i> Create Mailing List
                            </button>
                        </div>
                        <AllMailingListsViewTable />
                    </div>
                </div>
            </div>
        </div>
        <CreateMailingListModal v-if="show_modal" />
    </div>
</template>

<script>
import AllMailingListsViewTable from "./tables/allMailingListsViewTable.vue";
// import { crmMethods, crmComputed } from "@/state/helpers";
import CreateMailingListModal from "./modals/createMailingListModal.vue";

export default {
    components: { AllMailingListsViewTable, CreateMailingListModal },
    data: () => ({
        show_modal: false,
    }),
    computed: {},
    methods: {
        createMailingList() {
            this.show_modal = true;
            this.$nextTick(() => {
                this.$bvModal.show("createMailingList");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "createMailingList") {
                        this.show_modal = false;
                    }
                });
            });
        },
    },
    mounted() {},
};
</script>

<style></style>
