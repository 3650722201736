<template>
    <div>
        <div v-if="all_mailing_lists_loading_status == true" class="text-center">
            <b-spinner small type="grow"></b-spinner>
            Loading Mailing lists...
        </div>
        <div v-else>
            <div>
                <b-overlay :show="show">
                    <div class="mb-2 d-flex justify-content-end">
                        <b-button v-if="showButton" @click="onLinkProducts()" variant="success">+ Save Products to Group</b-button>
                    </div>
                    <div>
                        <webix-ui :config="ui" v-model="all_mailing_lists"></webix-ui>
                        <div id="allMailingListsViewTablePaging"></div>
                    </div>
                </b-overlay>
            </div>
        </div>
        <EditMailingListModal v-if="show_modal_edit" />
    </div>
</template>

<script>
import { crmMethods, crmComputed } from "@/state/helpers";
import Swal from "sweetalert2";
import EditMailingListModal from "../modals/editMailingListModal.vue";

export default {
    components: { EditMailingListModal },
    data: () => ({ show: false, showButton: false, show_modal_edit: false }),
    computed: {
        ...crmComputed,
        ui() {
            //eslint-disable-next-line
            let el = this;

            let ui = {
                id: "allMailingListsViewTable",
                view: "datatable",
                css: "datatableUsers webix_header_border webix_data_border",
                autoheight: true,
                //hover: "datatableHover",
                minHeight: 60,
                // rowHeight: 40,
                scrollY: false,
                scrollX: true,
                resizeColumn: true,
                select: false,
                pager: {
                    template: "{common.prev()} {common.pages()} {common.next()}",
                    container: "allMailingListsViewTablePaging",
                    size: 20,
                    group: 5,
                },
                columns: [
                    {
                        id: "actions",
                        header: "Actions",
                        template: function () {
                            return `
                        <div class="tableIcons">
                            <i class="mdi mdi-eye viewAction"></i>
                            <i class="mdi mdi-pencil editAction"></i>
                            <i class="mdi mdi-trash-can deleteAction"></i>
                            &nbsp;&nbsp;
                            </div>`;
                        },
                    },
                    {
                        id: "name",
                        header: "Name of Mailing List",
                        tooltip: false,
                        sort: "text",
                        width: 300,
                    },
                    {
                        id: "description",
                        header: "Mailing List Description",
                        tooltip: false,
                        fillspace: 1,
                    },
                ],
                ready: function () {
                    this.data.each(function (obj) {
                        if (obj.is_linked) {
                            this.getItem(obj.id).checkbox = "on";
                            this.refresh(obj.id);
                        }
                    });
                },
                on: {
                    onCheck: function (type, message, args) {
                        const getId = type;
                        const el = this.$scope.$parent;
                        //show the button because items have been clicked
                        el.showButton = true;

                        if (args == "on") {
                            el.addRolesToSaveToGroup(getId);
                        }

                        if (args == "off") {
                            el.removeFromRolesToSaveToGroup(getId);
                        }
                    },
                },
                onClick: {
                    //eslint-disable-next-line
                    viewAction: function (id, e, trg) {
                        let item = this.getItem(e.row);
                        let el = this.$scope.$parent.$parent;

                        el.setSelectedMailingListDetails(item);

                        el.$router.push(`/mailing-lists/${item.id}`);
                    },
                    //eslint-disable-next-line
                    editAction: function (id, e, trg) {
                        let item = this.getItem(e.row);
                        let el = this.$scope.$parent.$parent;

                        el.setSelectedMailingListDetails(item);
                        el.editMailingList();
                    },
                    //eslint-disable-next-line
                    deleteAction: function (id, e, trg) {
                        let item = this.getItem(e.row);
                        let el = this.$scope.$parent.$parent;

                        el.mailingListDeleteSwal(item);
                    },
                },
            };
            return ui;
        },
    },
    methods: {
        ...crmMethods,
        onLinkRoles() {
            Swal.fire({
                title: `Save Roles to this group`,
                // text: `${item.name}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.value) {
                    this.saveProductGroup({
                        id: this.$route.params.groupid,
                        name: this.selected_product_group_details.name,
                        description: this.selected_product_group_details.description,
                        linked_product_ids: [],
                        linked_role_ids: this.roleIds_to_save_to_group,
                    }).then(() => {
                        Swal.fire(`Roles saved to this group`, "", "success");
                        //refreshes the table on the screen
                        this.setAllLinkedProductsByGroup({ id: this.$route.params.groupid });
                    });
                }
            });
        },
        mailingListDeleteSwal(item) {
            Swal.fire({
                title: `Delete mailing list?`,
                text: `Are you sure that you would like to delete "${item.name}"`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.value) {
                    // item.on_collection = 0;
                    //eslint-disable-next-line
                    console.log(item);
                    this.deleteMailingList({
                        id: item.id,
                    }).then(() => {
                        Swal.fire(`Mailing List ${item.name} deleted`, "", "success");
                        //refreshes the table on the screen
                        this.setAllMailingLists();
                    });
                }
            });
        },
        editMailingList() {
            this.show_modal_edit = true;
            this.$nextTick(() => {
                this.$bvModal.show("editMailingListModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "editMailingListModal") {
                        this.show_modal_edit = false;
                    }
                });
            });
        },
    },
    mounted() {
        this.setAllMailingLists();
    },
};
</script>

<style lang="scss">
.pointer {
    cursor: pointer;
}
</style>
